import RouterComponent from "./Router";


function App() {
  return (
    <>
      <RouterComponent />
    </>
  );
}

export default App;

