import styles from './Footer.module.css';
import { useTranslation } from 'react-i18next';


export default function Footer() {
  const { t } = useTranslation();
    
  return (
    <div className={styles.footerContainer}>
      <p className={styles.title}>{t('footer.title')}</p>
      <p className={styles.address}>{t('footer.address')}</p>
    </div>
  );
}