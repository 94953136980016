import React, { useState, useEffect } from 'react';
import { Toolbar } from "../../componets/Toolbar";
import { MainBlock } from "../../componets/MainBlock";
import { Footer } from "../../componets/Footer";
import LanguagePopover from "../../componets/LanguagePopover";
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Fab from '@mui/material/Fab';
import TelegramIcon from '@mui/icons-material/Telegram';
import Tooltip from '@mui/material/Tooltip';


export default function HomePage() {

  const { t } = useTranslation();

  useEffect(() => {
    const banner = document.querySelector('.sale-banner');
    banner.classList.add('animate');
  }, []);
  return (
    <>
      <Tooltip title="Зв'язатись з нами">
        <Fab
          component={Link}
          href="https://telegram.me/AleksandrGolomoziy"
          target="_blank"
          color="primary"
          style={{ position: 'fixed', right: 30, bottom: 55, zIndex: 10 }}
        >
          <TelegramIcon fontSize="large" color="width" />
        </Fab>
      </Tooltip>
      <Toolbar />
      <LanguagePopover />
      <div className="sale-banner">
        {/* {t('productBlock.sale')} -25% */}
      </div>
      <MainBlock />
      <Footer />
    </>
  );
}