import { Toolbar } from "../../componets/Toolbar";
import { ProductBlock } from "../../componets/ProductBlock";
import { Footer } from "../../componets/Footer";
import LanguagePopover from "../../componets/LanguagePopover";

import Link from '@mui/material/Link';
import Fab from '@mui/material/Fab';
import TelegramIcon from '@mui/icons-material/Telegram';
import Tooltip from '@mui/material/Tooltip';

export default function ProductPage() {

  return (
    <>
      <Tooltip title="Зв'язатись з нами">
        <Fab
          component={Link}
          href="https://telegram.me/Nezlamnasyla_bot"
          target="_blank"
          color="primary"
          style={{ position: 'fixed', right: 30, bottom: 55, zIndex: 10 }}
        >
          <TelegramIcon fontSize="large" color="width" />
        </Fab>
      </Tooltip>
      <Toolbar />
      <LanguagePopover />
      <ProductBlock />
      <Footer />
    </>
  );
}