import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import cartActions from "../../redux/actions/cart-actions";
import { batteries } from "../../constants/batteries";
import { useTranslation } from 'react-i18next';
import { Button, Stack, Grid } from "@mui/material";
import styles from './MainBlock.module.css';


export default function MainBlock() {
  const products = useSelector(state => state.cart.items);
  const productInStock = useSelector(state => state.cart.balance);
  const dispatch = useDispatch();

  const { t } = useTranslation();
    
  return (
    <Grid container justifyContent="center" spacing={4} >
      {batteries.map((battery, index) => (
        <Grid key={index} item className={styles.cardContainer}>
          <Stack
            className={styles.contentContainer}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            // onClick={onClick}
            component={Link}
            to={`/${battery.id}`}
          >
            <p className={styles.cardTitle}>{t(battery.name)}</p>
            {battery.sale && (
              <>
                <div className={styles.discountBanner} >
                  <span>{t('productBlock.discount')} -25%</span>
                </div>
                <p className={styles.sale}>{battery.price*0.75}грн</p>
              </>
            )}
            <p className={battery.sale ? styles.price : styles.priceWithoutDiscount}>{battery.price}грн</p>
            <div className={styles.imgContainer}
              style={{
                backgroundImage: `url(${battery.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}>
            </div>
            {products.find(product => product.id === battery.id)
              ? <Button variant="contained"> {t('buttons.inBasket')} </Button>
              : (productInStock.find(el => el.id === battery.id).ofGoodsStock !== 0)
                ? <Button onClick={() => dispatch(cartActions.addProduct(battery.id))} variant="contained">{t('buttons.add')}</Button>
                : <Button variant="contained" disabled>{t('buttons.outOfStock')}</Button>
            }
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}