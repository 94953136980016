import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ua from "./locales/ua.json";
import ru from "./locales/ru.json";

// const domain =
//   document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];
i18n.use(initReactI18next).init({
  resources: { ua, en, ru },
  lng: "ua",
  interpolation: {
    escapeValue: false,
  },
});

const localStorageLang = localStorage.getItem("lang");

const existingLangs = Object.keys(i18n.store.data);
if (localStorageLang && !existingLangs.includes(localStorageLang)) {
  localStorage.setItem("lang", "ua");
}

const langToUse = !localStorageLang ? "ua" : localStorageLang;
i18n.changeLanguage(langToUse);
if (!localStorageLang) localStorage.setItem("lang", "ua");
export default i18n;
