import {
    battery_12v_100ah,
    battery_12v_200ah,
    battery_12v_200ah_2,
    battery_48v_200ah,
    inverter,
    battery_inverter
} from "../assets/img";


const batteries = [
    // {
    //     // name: "12V 200Ah LiFePO4 Battery | 2.56kWh & 1.28kW",
    //     name: 'name.12V_200Ah_LiFePO4',
    //     url: battery_12v_200ah,
    //     url_2: battery_12v_200ah_2,
    //     price: 32800,
    //     sale: true,
    //     title: 'title.12V_200Ah_LiFePO4',
    //     description: 'description.12V_200Ah_LiFePO4',
    //     options: [
    //         'options_12V 200Ah.capacity',
    //         'options_12V 200Ah.voltage',
    //         'options_12V 200Ah.energy',
    //         'options_12V 200Ah.amperage',
    //         'options_12V 200Ah.power',
    //         'options_12V 200Ah.сhargingVoltage',
    //         'options_12V 200Ah.lifeTime',
    //         'options_12V 200Ah.proportions',
    //         'options_12V 200Ah.weight',
    //         'options_12V 200Ah.workingTemperature',
    //         'options_12V 200Ah.compound'
    //     ],
    //     id: "12V 200Ah LiFePO4"
    // },
    {
        // name: "Комплект 51.2V 200Ач LiFePO4 акумулятор залізофосфатний + Inverter HYG5032",
        name: 'name.battery_inverter',
        url: battery_inverter,
        price: 132905,
        sale: false,
        description: "",
        options: [],
        id: "48V 200Ah + HYG5032"
    },
    // {
    //     // name: "12V 100Ah LiFePO4 battery | 1.28kWh & 1.28kW",
    //     name: 'name.12V_100Ah_LiFePO4',
    //     url: battery_12v_100ah,
    //     price: 18000,
    //     sale: false,
    //     title: 'title.12V_100Ah_LiFePO4',
    //     description: 'description.12V_100Ah_LiFePO4',
    //     options: [
    //         'options_12V_100Ah.capacity',
    //         'options_12V_100Ah.voltage',
    //         'options_12V_100Ah.energy',
    //         'options_12V_100Ah.power',
    //         'options_12V_100Ah.сhargingVoltage',
    //         'options_12V_100Ah.lifeTime',
    //         'options_12V_100Ah.proportions',
    //         'options_12V_100Ah.weight',
    //         'options_12V_100Ah.workingInTheCold',
    //         'options_12V_100Ah.compound'
    //     ],
    //     id: "12V 100Ah LiFePO4"
    // },
    {
        // name: "48V 200Ah LiFePO4 Battery | 4.8kWh & 4.8kW",
        name: 'name.51.2V_200Ah_LiFePO4',
        url: battery_48v_200ah,
        price: 105400,
        sale: false,
        title: 'title.51.2V_200Ah_LiFePO4',
        description: 'description.51.2V_200Ah_LiFePO4',
        options: [
            'options_51.2V_200Ah.capacity',
            'options_51.2V_200Ah.voltage',
            'options_51.2V_200Ah.energy',
            'options_51.2V_200Ah.amperage',
            'options_51.2V_200Ah.power',
            'options_51.2V_200Ah.сhargingVoltage',
            'options_51.2V_200Ah.lifeTime',
            'options_51.2V_200Ah.proportions',
            'options_51.2V_200Ah.weight',
            'options_51.2V_200Ah.workingTemperature',
            'options_51.2V_200Ah.compound'
        ],
        id: "48V 200Ah LiFePO4"
    },
    {
        name: 'name.inverter',
        url: inverter,
        price: 34500,
        sale: false,
        title: 'title.inverter',
        description: 'description.inverter',
        options: [
            {
                mode: 'options_inverter.inverterMode',
                modeOptions: [
                    'options_inverter.inverterMode_power',
                    'options_inverter.inverterMode_InputDC',
                    'options_inverter.inverterMode_exitAC'
                ]
            },
            {
                mode: 'options_inverter.chargerMode',
                modeOptions: [
                    'options_inverter.chargerMode_InputAC',
                    'options_inverter.chargerMode_exitDC',
                    'options_inverter.chargerMode_exitAC'
                ]
            },
            {
                mode: 'options_inverter.solarChargingMode',
                modeOptions: [
                    'options_inverter.solarChargingMode_maxPower',
                    'options_inverter.minSolarVoltage',
                    'options_inverter.maxSolarVoltage',
                    'options_inverter.maxInputCurrent'
                ]
            }
        ],
        id: "Inverter HYG5032"
    },
];

export { batteries };
           
