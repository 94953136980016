import styles from './ThankYouPage.module.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Typography } from "@mui/material";


export default function ProductPage() {
    const { t } = useTranslation();

    return (
        <div className={styles.conteiner}>
            <div>
                <h3 className={styles.title}> {t('thankYouPage.title')}</h3> 
                <h3 className={styles.title}>{t('thankYouPage.subtitle')}</h3>
            </div>
            <Typography
                className={styles.link}
                variant="h6"
                noWrap
                component={Link}
                to={`/`}
                sx={{
                  mb: '30px',
                //   display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
                >
                    {t('thankYouPage.goBack')}
                </Typography>
        </div>
    );
}