import  { useState } from 'react';
import { useParams, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import cartActions from "../../redux/actions/cart-actions";
import { batteries } from "../../constants/batteries";

import { Button, Stack, IconButton, TextField, Modal, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import styles from './ProductBlock.module.css';


const CountTextField = styled(TextField)({
    width: '60px',
    height: '45px',
    padding: '0px',
  '& label.Mui-focused': {
      color: 'green',
      padding: '0px',  
    },
    '& .MuiInput-sizeSmall':{
      padding: '0px',
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          border: '0',
          padding: '0',
    },
  },
});


export default function ProductBlock() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const { productId } = useParams();
    const product = batteries.find(battery => battery.id === `${productId}`);

    const products = useSelector(state => state.cart.items);
    // const selectedProduct = products.find(el => el.id === `${productId}`);

    // const productInStock = useSelector(state => state.cart.balance);
    const balance = useSelector(state => state.cart.balance);
    const balanceBattery = balance.find(el => el.id === `${productId}`)

    const quantity = useSelector(state => state.cart.quantity);
    const quantitySelectedProduct = quantity.find(el => el.id === `${productId}`)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    if (!product) return <Navigate to={"/"} />
    
    return (
        <>
            <div className={styles.container}>
                <div onClick={handleOpen} className={styles.imgContainer}>
                    
                    {product.url_2 ? (
                        <Stack spacing={2}>
                            <img src={product.url} alt="" width="80%" height="80%" />
                            <img src={product.url_2} alt="" width="80%" height="80%" style={{ marginBottom: '12px' }} />
                        </Stack>) : (
                        <img src={product.url} alt="" width="80%" height="80%" />
                    )
                    }
                </div>
                <div className={styles.contentContainer}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <p className={styles.title}>{t(product.name)}</p>
                        {product.sale && (
                            <>
                                <div className={styles.discountBanner} >
                                    <span>{t('productBlock.discount')} -25%</span>
                                </div>
                                <p className={styles.sale}>{product.price * 0.75}грн</p>
                            </>
                        )}
                        <p className={product.sale ? styles.price : styles.priceWithoutDiscount}>{product.price} грн</p>
                        <h2 className={styles.titleDescription}>{t(product.title)}</h2>
                        <p className={styles.description}>{t(product.description)}</p>
                        <div>
                            <h3 className={styles.specification}>{t('productBlock.specifications')}</h3>
                            {productId === '48V 200Ah + HYG5032' &&
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Typography
                                        className={styles.link}
                                        component={Link}
                                        to={`/48V 200Ah LiFePO4`}
                                    >
                                        {t('productBlock.specifications_51.2V_200Ah')}
                                    </Typography>
                                    <Typography
                                        className={styles.link}
                                        component={Link}
                                        to={`/Inverter HYG5032`}
                                    >
                                        {t('productBlock.specifications_inverter')}
                                    </Typography>
                                </Stack>
                            }
                            
                            {productId === 'Inverter HYG5032' &&
                                product.options.map((el, index) => (
                                    <div key={index}>
                                        <p className={styles.subtitle}>{t(el.mode)}</p>
                                        {el.modeOptions.map((item, index) => <p key={index} className={styles.option}>{t(item)}</p>)}
                                     
                                    </div>
                                ))}
                            
                            {productId !== '48V 200Ah + HYG5032' && productId !== 'Inverter HYG5032' &&
                                product.options.map((el, index) => (<p key={index} className={styles.option}>{t(el)}</p>))
                            }
                        </div>
                        <p style={{fontSize: '28px', fontWeight: '800'}}>немає в наявності</p>
                        {/* <p className={styles.inStock}>{t('productBlock.inStock')} {balanceBattery.ofGoodsStock} {t('productBlock.piece')}</p> */}
                        {/* {productId === 'Inverter HYG5032'
                            ? <p className={styles.inStock}>{t('productBlock.inStock')} 3 {t('productBlock.piece')}</p>
                            : <p className={styles.inStock}>{t('productBlock.inStock')} {balanceBattery.ofGoodsStock} {t('productBlock.piece')}</p>} */}
                        {/* {(balanceBattery.ofGoodsStock !== 0)
                            ? (<div className={styles.counterBox}>
                                <IconButton onClick={() => dispatch(cartActions.decrement(productId))}>
                                    <RemoveIcon />
                                </IconButton>
                                <CountTextField
                                    type="number"
                                    // min={1} max={5}
                                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    size="small"
                                    // value={selectedProduct ? selectedProduct.count : 1}
                                    value={quantitySelectedProduct.amount}
                                    onChange={(e) => {
                                        if (e.target.value < 1 || e.target.value > balanceBattery.ofGoodsStock) {
                                            return;
                                        }
                                                                        
                                        dispatch(cartActions.addQuantity(e.target.value, productId))
                                    }}
                                />
                                <IconButton onClick={() => dispatch(cartActions.increment(productId, balanceBattery.ofGoodsStock))}>
                                    <AddIcon />
                                </IconButton>
                            </div>)
                            : <></>}
                        {products.find(product => product.id === productId)
                            ? <Button ovariant="contained" fullWidth> {t('buttons.inBasket')} </Button>
                            : (balanceBattery.ofGoodsStock !== 0)
                                ? <Button onClick={() => dispatch(cartActions.addProduct(productId))} variant="contained" fullWidth>{t('buttons.add')}</Button>
                                : <Button variant="contained" disabled>{t('buttons.outOfStock')}</Button>
                        } */}
                    </Stack>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={styles.modalConteiner}>
                    <img src={product.url} alt="" width="100%" height="100%" />
                </div>
            </Modal>
        </>
    );
}