import types from '../constants/cart-types';
import { batteries } from "../../constants/batteries";

const addProduct = productId => ({
    type: types.ADD,
    payload: batteries.find(battery => battery.id === productId),
});

const deleteProduct = productId => ({
    type: types.DELETE,
    payload: productId,
});

const addQuantity = (quantity, productId) => ({
    type: types.ADD_QUANTITY,
    payload: {
        quantity,
        productId,
    },
});

const increment = (productId, maxQuantity) => ({
    type: types.INCREMENT,
    payload: {
        productId,
        maxQuantity,
    },
});

const decrement = (productId) => ({
    type: types.DECREMENT,
    payload: productId,
});

const clearCart = () => ({
    type: types.CLEAR,
    payload: [],
});

const clearAmount = (orderedProducts) => ({
    type: types.CLEAR_AMOUNT,
    payload: orderedProducts,
});

const calculateRemainder = (batteryBalance) => ({
    type: types.CALCULATE_REMAINDER,
    payload: batteryBalance,
});


export default {
    addProduct,
    deleteProduct,
    addQuantity,
    increment,
    decrement,
    clearCart,
    calculateRemainder,
    clearAmount
}