import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";

import cartActions from "../../redux/actions/cart-actions";
import styles from './Order.module.css';
import { Stack, Button, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import CheckIcon from '@mui/icons-material/Check';
import { FaTelegram } from "react-icons/fa";
import { FaViber } from "react-icons/fa";


export default function Order({ onCloseModal }) {

    const dispatch = useDispatch();
    
    const [contactTelegram, setСontactTelegram] = React.useState('');
    const [contactViber, setСontactViber] = React.useState('');

    const handleChangeTelegram = (e) => setСontactTelegram(e.target.value);
    const handleChangeViber = (e) => setСontactViber(e.target.value);
    
    const products = useSelector(state => state.cart.items);
    const quantity = useSelector(state => state.cart.quantity);

    const { t } = useTranslation();

    const batteryBalance = [];
    const orderedProducts = [];
    const summ = [];

    for (let i = 0; i < products.length; i += 1) {

        const quantit = quantity.find(el => el.id === products[i].id);
        summ.push(Number(products[i].sale ? products[i].price * 0.75 : products[i].price) * Number(quantit.amount));
      
        batteryBalance.push({
            id: products[i].id,
            count: quantit.amount
        });
      
        orderedProducts.push({
            name: products[i].id,
            count: quantit.amount,
        });
    };

    const priceTotal = summ.reduce((acc, value) => acc + value, 0);

    const  handleClick = async () => {    

        const data = {
            telegram: contactTelegram,
            viber: contactViber,
            products: orderedProducts,
            priceTotal: priceTotal
        };

        // dispatch(cartActions.calculateRemainder(orderedProducts[0].count, orderedProducts[0].name))     
        dispatch(cartActions.calculateRemainder(batteryBalance));
        
        const message = `Отримано замовлення від 
telegram: @${data.telegram}, viber: ${data.viber}. 
Загальна сума замовлення  ${data.priceTotal} грн. 

Замовили: ${data.products.map(el => el.name).join(", ")}. 
Кількість відповідно: ${data.products.map(el => el.count).join("шт, ")}шт `; 

        const tokenTelBot = '5983166279:AAH7pP9apPLizPUNBiUoOhwMZWSVZvUQu3s'; 
        const telegramUserId = 563175416
     
  
        //  my  1120172313
        // Ю.А  168247963 
        // A.Г. 563175416
                
await axios({
    method: "GET",
    url:
        `https://api.telegram.org/bot${tokenTelBot}/sendMessage?chat_id=${telegramUserId}&text=${
            encodeURIComponent(
                String(
                     `${message}`
                )        
            )
        }`
});

        setСontactTelegram('');
        setСontactViber('');

        dispatch(cartActions.clearAmount(orderedProducts));  
        dispatch(cartActions.clearCart());

        onCloseModal();
    };
    
    return (
        <>
            <h3 className={styles.title}>{t('order.selectedProducts')}</h3>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
            >
                {batteryBalance.map((product, index) => (
                    <div key={index}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <CheckIcon style={{ fill: 'blue' }} fill="blue" />
                            {/* <p>{product.name.split("|").filter(el => el != "")[0]} - {product.count} шт.</p> */}
                            <p>{product.id} - {product.count} {t('productBlock.piece')}.</p>
                        </Stack>
                    </div>
                ))}
            </Stack>
            <p className={styles.totalOrder}>{t('order.orderPrice')} {priceTotal} грн</p>
            <h3 className={styles.subtitle}>{t('order.contacts')}</h3>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
            >
                <TextField
                    id="input-with-icon-textfield"
                    fullWidth
                    value={contactTelegram}
                    onChange={handleChangeTelegram}
                    // label="TextField"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaTelegram fill='#0088cc' size='1.8em' />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <TextField
                    id="input-with-icon-textfield"
                    fullWidth
                    value={contactViber}
                    onChange={handleChangeViber}
                    // label="TextField"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaViber fill='#59267c' size='1.8em' />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </Stack>
            <Button
                component={Link}
                to={`/thank_you`}
                fullWidth
                style={{ marginTop: '30px' }}
                variant="contained"
                onClick={handleClick}
                disabled={contactTelegram != '' || contactViber != '' ? false : true}
            >
                {t('buttons.checkout')}
            </Button>
        </>
    );
}