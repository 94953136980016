import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";

// components
import i18n from "i18next";

import MenuPopover from "../componets/MenuPopover";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "ua",
    label: "Ukrainian",
    icon: "/static/icons/ic_flag_ua.svg",
  },    
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
  },
  {
    value: "ru",
    label: "Russian",
    icon: "/static/icons/ic_flag_ru.svg",
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const localStorageLang = localStorage.getItem("lang") || "ru";
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    const selected = LANGS.find((el) => el.label === e.target.innerText);
    if (selected && selected.value) {
      localStorage.setItem("lang", selected.value);
      i18n.changeLanguage(selected.value);
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
              sx={{
          marginLeft: 4,
          padding: 0,
          width: 55,
          height: 55,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <img
          src={LANGS.find((el) => el.value === localStorageLang).icon}
          alt={LANGS.find((el) => el.value === localStorageLang).label}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={
                option.value ===
                LANGS.find((el) => el.value === localStorageLang).value
              }
              onClick={(e) => handleClose(e)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}