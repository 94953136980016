import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import cartActions from "../../redux/actions/cart-actions";
import {Order} from "../Order"

import { Stack, Button, Typography, Box, IconButton, TextField, Modal } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import styles from './Cart.module.css';


const CountTextField = styled(TextField)({
    width: '55px',
    height: '40px',
    padding: '0px',
    '& .MuiInput-sizeSmall':{
      padding: '0px',
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          border: '0',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 330,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};


export default function Cart() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const products = useSelector(state => state.cart.items)
  const quantity = useSelector(state => state.cart.quantity);
  const productInStock = useSelector(state => state.cart.balance);
 
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const summ = []

  for (let i = 0; i < products.length; i += 1) {
  const quantit = quantity.find(el => el.id === products[i].id);
  const price = products[i].sale ? products[i].price * 0.75 : products[i].price;
  
  summ.push(Number(price) * Number(quantit.amount));
}

  // for (let i = 0; i < products.length; i += 1) {
  //   const quantit = quantity.find(el => el.id === products[i].id);
    
  //   summ.push(Number(products[i].price*0.75)*Number(quantit.amount))
  //  }

  const priceTotal = summ.reduce((acc, value) => acc + value, 0)

  return (
    <>
      <Box
        // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        sx={{ width: 340, padding: "7px", textAlign: "center" }}
        role="presentation"
      >
        <Typography variant="h5" gutterBottom>
          {t('cart.shoppingCart')}
        </Typography>
        {products.map((product, index) => (
          <div key={index} className={styles.productContainer}>
            <div className={styles.container}>
              <div className={styles.imgContainer}>
                <img src={product.url} alt="" width="100%" height="100%" className={styles.img} />
              </div>
              <div className={styles.contentContainer}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <p className={styles.title}>{t(product.name)}</p>
                  {
                    product.sale
                      ? <p className={styles.price}>{product.price * 0.75} грн</p>
                      : <p className={styles.price}>{product.price} грн</p>
                  }
                </Stack>
              </div>
            </div>
            <div className={styles.count}>
              <div className={styles.counterBox}>
                <IconButton onClick={() => dispatch(cartActions.decrement(product.id))}>
                  <RemoveIcon />
                </IconButton>
                <CountTextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  size="small"
                  // value={product.count}
                  value={quantity.find(el => el.id === `${product.id}`).amount}
                  onChange={(e) => {
                    if (e.target.value < 1 || e.target.value > productInStock.find(el => el.id === product.id).ofGoodsStock) {
                      return;
                    }
                    dispatch(cartActions.addQuantity(e.target.value, product.id))
                  }}
                />
                <IconButton onClick={() => dispatch(cartActions.increment(product.id, productInStock.find(el => el.id === product.id).ofGoodsStock))}>
                  <AddIcon />
                </IconButton>
              </div>
              <Button fullWidth onClick={() => dispatch(cartActions.deleteProduct(product.id))}>{t('buttons.delete')}</Button>
            </div>
          </div>
        ))}
        {priceTotal
          ? <>
            <p className={styles.priceTotal}>{t('cart.selectedGoods')} {priceTotal} грн</p>
            <Button variant="contained" fullWidth onClick={handleOpen}>
              {t('buttons.buy')}
            </Button>
          </>
          : <></>
        }
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Order onCloseModal={handleClose} />
        </Box>
      </Modal>
    </>
  );
}