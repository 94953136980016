import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage, ProductPage, ThankYouPage } from "./pages";


export default function RouterComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:productId" element={<ProductPage />} />
        <Route path="/thank_you" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
}


// BATTERIES, INVERTER, ABOUT US, CONTACTS, BLOG