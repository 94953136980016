import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './App';
import i18n from "./i18n";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
    <Provider store={store.store}>
      {/* <Provider store={store}> */}
      <PersistGate loading={null} persistor={store.persistor}>
        <App />
      </PersistGate>
    </Provider>    
  </React.StrictMode>
);