import { combineReducers } from "redux";
import types from '../constants/cart-types';


const items = (state = [], {type, payload}) => {
    switch (type) {
        case types.ADD:
            return [...state, payload];
        
        case types.DELETE:
            return state.filter(product => product.id !== payload); 
        
        case types.CLEAR:
            return [...payload];
        
        default:
            return state;
    }    
};


const balance = (state = [
    {
        ofGoodsStock: 30,
        id: "12V 100Ah LiFePO4"
    },
    {
        ofGoodsStock: 30,
        id: "12V 200Ah LiFePO4"
    },
    {
        ofGoodsStock: 30,
        id: "48V 200Ah LiFePO4"
    },
    {
        ofGoodsStock: 3,
        id: "Inverter HYG5032"
    },
    {
        ofGoodsStock: 30,
        id: "48V 200Ah + HYG5032"
    }
], { type, payload }) => {

    switch (type) {

        case types.CALCULATE_REMAINDER:
            for (let i = 0; i < payload.length; i += 1){
                const item = state.find(el => el.id === payload[i].id);
                item.ofGoodsStock = item.ofGoodsStock - Number(payload[i].count);    
            };
            return [...state];

        default:
            return state;
    }
};


const quantity = (state = [
    {
        amount: 1,
        id: "12V 100Ah LiFePO4"
    },
    {
        amount: 1,
        id: "12V 200Ah LiFePO4"
    },
    {
        amount: 1,
        id: "48V 200Ah LiFePO4"
    },
    {
        amount: 1,
        id: "Inverter HYG5032"
    },
    {
        amount: 1,
        id: "48V 200Ah + HYG5032"
    }
], { type, payload }) => {

    switch (type) {

        case types.ADD_QUANTITY:
            const el = state.find(el => el.id === payload.productId);
            if (state.length && el) {
                el.amount = Number(payload.quantity);
                return [...state];
            } return state; 
        
        case types.INCREMENT:
            const item = state.find(el => el.id === payload.productId);
            
            if (state.length && item) {
                item.amount = item.amount + 1;
                if (item.amount > payload.maxQuantity) {
                    item.amount = payload.maxQuantity;
                    return [...state];
                }
                return [...state];
            }  return state;         
            
        case types.DECREMENT:
            const element = [...state].find(el => el.id === payload);
            if (state.length && element) {
                element.amount = element.amount - 1;
                if (element.amount < 1) {
                    element.amount = 1
                    return [...state];
                }
                return [...state];
            } return state; 
        
        case types.CLEAR_AMOUNT:
            for (let i = 0; i < payload.length; i += 1){
                const item = state.find(el => el.id === payload[i].name);
                item.amount = 1   
            };
            return [...state];
                   
        default:
            return state;
    }
};

export default combineReducers({
    items,
    balance,
    quantity,
});